import { connect } from 'react-redux';
import React from 'react';
import { Common } from 'ws-scripts/modules/common';
import { Source } from '../components/Source';
import { ParentStarSVG } from '../components/ParentStarSVG';
import Disclaimer from '../components/Disclaimer';

import '../global-sass/main.scss';
import Intersection from '../components/hoc/Intersection';

const Slider = React.lazy(() =>
	import(
		/* webpackChunkName: "Slider" , webpackPrefetch: true */ '../components/Slider'
	)
);

export class WidgetContainer extends React.Component {
	render() {
		return (
			<Common
				render={({ prefs, labels }) => {
					const {
						sources,
						reviewTruncationLength,
						arrowStyle,
						fontSize,
						reviewDateFontSize,
						nextSlideControlPosition,
						previousSlideControlPosition,
						ratingStarFullFillColor
					} = prefs;

					const sourcesArray = sources.split(',');
					const singleSource = sourcesArray.length === 1;

					return (
						<>
							<ParentStarSVG />
							{singleSource && (
								<Source
									source={sources}
									singleSource={singleSource}
								/>
							)}
							<Intersection placeholder={<div>Loading...</div>}>
								<Slider
									singleSource={singleSource}
									arrowStyle={
										arrowStyle === 'BLANK' ||
										arrowStyle === ''
											? 'arrow1'
											: arrowStyle
									}
									reviewTruncationLength={parseInt(
										reviewTruncationLength,
										10
									)}
									labels={labels}
									fontSize={`ddc-font-size-${fontSize}`}
									reviewDateFontSize={`ddc-font-size-${reviewDateFontSize}`}
									nextSlideControlPosition={
										nextSlideControlPosition
									}
									previousSlideControlPosition={
										previousSlideControlPosition
									}
									ratingStarFullFillColor={
										ratingStarFullFillColor
									}
								/>
							</Intersection>
							<Disclaimer labels={labels} fontSize={fontSize} />
						</>
					);
				}}
			/>
		);
	}
}

WidgetContainer.propTypes = {};

const mapStateToProps = (_state) => {
	return {};
};

const mapDispatchToProps = (_dispatch) => ({});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
