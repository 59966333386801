import React, { useState, useLayoutEffect, useRef, Suspense } from 'react';
import PropTypes from 'prop-types';

const Intersection = ({ children, placeholder, observerOptions }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const targetRef = useRef(null);

	useLayoutEffect(() => {
		if (!targetRef.current) {
			// Return empy function for eslint consistent-return
			return () => {};
		}

		if (!('IntersectionObserver' in window)) {
			// Load component if IntersectionObserver not supported
			setIsLoaded(true);

			// Return empy function for eslint consistent-return
			return () => {};
		}
		const observer = new IntersectionObserver(([entry]) => {
			if (!entry.isIntersecting || !(entry.intersectionRatio > 0)) return;
			setIsLoaded(true);

			// Stop all observe after component is loaded
			observer.disconnect();
		}, observerOptions);

		observer.observe(targetRef.current);

		return () => observer.disconnect();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// ignoring exhaustive-deps here because the observerOptions are an object that would cause this
	// useEffect to trigger every time the parent component rerenders. observerOptions should never change.

	if (isLoaded) {
		return <Suspense fallback={placeholder}>{children}</Suspense>;
	}

	return React.cloneElement(placeholder, { ref: targetRef });
};

Intersection.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]).isRequired,
	placeholder: PropTypes.element.isRequired,
	observerOptions: PropTypes.shape({})
};

Intersection.defaultProps = {
	observerOptions: {
		rootMargin: '100px'
	}
};

export default Intersection;
