import React from 'react';
import PropTypes from 'prop-types';

const Disclaimer = ({ fontSize, labels }) => {
	return (
		<div className="row d-flex justify-content-center align-items-center mt-4">
			<div className="col-xs-10">
				<p className={`ddc-font-size-${fontSize} text-center`}>
					{labels.get('DEALER_REVIEWS_LEGAL_DISCLAIMER_VERBIAGE')}
				</p>
			</div>
		</div>
	);
};

Disclaimer.propTypes = {
	fontSize: PropTypes.string,
	labels: PropTypes.shape({
		get: PropTypes.func
	}).isRequired
};

Disclaimer.defaultProps = {
	fontSize: ''
};

export default Disclaimer;
