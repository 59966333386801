import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';

export const Source = ({ source, singleSource }) => {
	const sourceLC = source.toLowerCase();
	const sourceWrapClasses = ['source'];
	const sourceText = singleSource ? `powered-by-${sourceLC}` : sourceLC;
	if (singleSource) {
		sourceWrapClasses.push(
			'd-inline-flex',
			'align-items-center',
			'ml-2',
			'ml-sm-10'
		);
	}
	return (
		<div className={setClassNames(sourceWrapClasses)}>
			<img
				src={`/static/assets/images/logos/social-media/${sourceText}.svg`}
				width={singleSource ? '151' : '30'}
				alt={sourceText.replace(/-/g, ' ')}
			/>
		</div>
	);
};

Source.propTypes = {
	source: PropTypes.string.isRequired,
	singleSource: PropTypes.bool.isRequired
};
