import { SET_REVIEW_DATA, ADD_REVIEW_DATA } from '../actions/types';

export default function reviewData(state = [], { type, payload, error }) {
	if (!error && payload) {
		switch (type) {
			case SET_REVIEW_DATA:
				return payload;
			case ADD_REVIEW_DATA:
				return [...state, ...payload];
			// no default
			default:
		}
	}
	return state;
}
