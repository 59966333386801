import React from 'react';

export const ParentStarSVG = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		className="parent-star-svg d-none"
	>
		<path
			id="review-star-left"
			d="M14.75,23.63V1.55l-3.59,11H-.44L9,19.41l-3.59,11,9.39-6.82Z"
		/>
		<path
			id="review-star-right"
			d="M-.44,23.63,9,30.45l-3.59-11,9.39-6.82H3.14l-3.58-11V23.63Z"
		/>
	</svg>
);
