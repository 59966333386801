import { SET_PAGE_DATA, SET_ACCOUNT_NAME } from '../actions/types';

const intialState = {
	page: 0,
	accountName: 'danielsbmwbmw'
};

export default function appData(state = intialState, { type, payload, error }) {
	if (!error && payload) {
		switch (type) {
			case SET_PAGE_DATA:
				return { ...state, page: payload };
			case SET_ACCOUNT_NAME:
				return { ...state, accountName: payload };
			// no default
			default:
		}
	}
	return state;
}
